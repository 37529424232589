@import '../../../../assets/scss/index.scss';

.step-name__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step-name {
  line-height: 24px;
}

.step-name__tooltip-wrapper {
  margin-left: 4px;
  display: flex;
  margin-top: 6px;
  line-height: 24px;
}

.MuiTooltip-tooltip {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 16px !important;
  padding: 8px 16px !important;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
}

.MuiIcon-fontSizeSmall {
  width: 16px !important;
  height: 16px !important;
}

.step-audit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
  font-size: 12px;
}

.step-audit__status {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 100px;
}

.decision-summary-icon {
  width: 16px !important;
  height: 16px !important;
}

.step-audit__additional-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.step-audit__view-all {
  margin-left: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: $primary;
  font-weight: bold;
  padding: 8px 0;

  :hover {
    cursor: pointer;
    color: $primaryDark;
  }
}

.side-modal__wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.side-modal {
  all: unset;
  position: fixed;
  width: 43vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
}

.side-modal__top-bar {
  width: 100%;
  height: 18px;
  background-color: $primary;
  color: var(--color-neutral-main);
}

.side-modal__content-wrapper {
  height: calc(100vh - 18px);
  padding: 32px;
}

.side-modal__close-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.side-modal__close-button {
  cursor: pointer;
}

.side-modal__header {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-neutral-main);
  margin-bottom: 24px;
}

.side-modal__content {
  height: 80%;
  overflow-y: auto;
  margin-bottom: 32px;
}

.evaluation-info-container {
  padding-bottom: 0 !important;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.evaluation-info-container__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.evaluation-info-container__header__update-info {
  color: $textLow;
  font-size: 16px;
  font-weight: 400;
}

.step-audit-report__json-view {
  color: $textHigh;
  border: 1px solid $actionTextDisabled;
  font-size: 16px;
  border-radius: 4px;
  background-color: $textDisabled;
  padding: 24px;
  height: 100%;
  overflow: auto;
}

.report-icon {
  fill: $primary;
}

.report-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    svg .report-icon {
      fill: $primaryDark;
    }
  }
}
