@import '../../../../assets/scss/index';

.shell__headerV2 {
  width: 100%;
  padding-left: var(--space-md);
  padding-right: var(--space-md);
  padding-top: var(--space-lg);
  margin-bottom: var(--space-xxxl);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 100;
}

.shell__headerV2__mobile {
  padding: rsize(16) rsize(20);
  flex-direction: row-reverse;
  box-shadow: rsize(4) rsize(8) rsize(22) rgb(29 29 29 / 8%);
}

.shell__headerV2__logo--desktop {
  width: rsize(186);
  height: rsize(24);
}

.shell__headerV2__logo--mobile {
  width: rsize(155);
  height: rsize(20);
}

.shell__headerV2__nav {
  display: flex;
  align-items: center;
}

.shell__headerV2__link {
  color: $textPrimaryV2 !important;
  font-family: $primaryFontV2 !important;
  font-size: rsize(16);
  text-decoration: none;
  font-weight: 400;
  line-height: rsize(26);

  &:not(:last-child) {
    margin-right: rsize(25);
  }
}

.mobileMenu--containerV2 {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 346px;
  height: 100%;
  background: #f3e7d9;
  border-radius: rsize(0) rsize(16) rsize(16) rsize(0);

  .shell__headerV2 {
    &__nav {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__close {
      color: $textPrimaryV2;
    }

    &__link {
      display: flex;
      width: 100%;
      align-items: center;
      margin-right: 0;
      font-size: rsize(22);
      color: $textPrimaryV2;
      font-family: $primaryFontV2;
      padding: 0 rsize(32) rsize(32) rsize(32);
    }

    &__topMobileHeader {
      background-color: $whiteColor;
      width: 100%;
      height: rsize(60);
      border-top-right-radius: rsize(16);
      margin-bottom: rsize(32);
      display: flex;
      justify-content: space-between;
      padding: rsize(20);
      box-shadow: rsize(4) rsize(8) rsize(22) rgba(0, 0, 0, 0.08);
    }
  }
}
