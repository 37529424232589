::-webkit-scrollbar {
  width: rsize(4);
}

::-webkit-scrollbar-track {
  background-color: $scrollbarTrack;
  border-radius: rsize(4);
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbarThumb;
  border-radius: rsize(4);
  height: rsize(48);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}
