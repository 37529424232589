@import '../../assets/scss/index';

.technical-difficulties {
  margin-top: rsize(46);

  &__title__icon {
    color: $secondary;
    position: relative;
    right: 0;
    top: rsize(-13);
  }

  &__body {
    margin-top: rsize(24);
  }
}
