// Colors
$textHigh: #161657;
$textMedium: #34346d;
$textLow: #73739a;
$textDisabled: #ededf2;
$actionTextDisabled: #a6a6bf;
$actionBackgroundDisabled: $textDisabled;
$scrollbarTrack: $textDisabled;
$scrollbarThumb: $textLow;
$border: $textDisabled;

$primary: #3f3cec;
$primaryDark: #0010b8;

$secondary: #1fddb3;
$secondaryBackground: #e9f8f8;

$info: $textHigh;
$infoBackground: $textDisabled;
$success: $secondary;
$successBackground: $secondaryBackground;
$warning: #fcbd39;
$warningBackground: #fffaf0;
$error: #af332c;
$errorBackground: #faeeee;

$mobileBodyCopy: rgba(0, 0, 0, 0.87);

$whiteColor: #fff;
$textPrimaryV2: #000;

// Fonts
// This is handled by the MUI theme
$primaryFontV2: 'Acid Grotesk', sans-serif;
$sansSerif: 'Gilroy', sans-serif;
$serif: 'Tiempos Headline', sans-serif;
