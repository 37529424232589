@import '../../../assets/scss/index';

.one-column-layout {
  max-width: rsize(673);
  width: 100%;
  display: flex;
  padding: rsize(40) rsize(20);
  margin: 0 auto;

  &__content {
    flex: 1;
  }

  &--desktop,
  &--tablet {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  &--mobile {
    flex-wrap: wrap;
    flex-direction: column;

    .one-column-layout__content {
      flex: 1 1 100%;
      order: 2;
    }
  }
}
