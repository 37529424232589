@import '../../assets/scss/index';

.shell__container {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.shell__header {
  width: 100%;
  padding-left: var(--space-md);
  padding-right: var(--space-md);
  padding-top: var(--space-lg);
  margin-bottom: var(--space-xxxl);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 100;
}

.shell__header--desktop {
  position: relative;
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);

  &::after {
    display: block;
    width: rsize(300);
    height: rsize(20);
    content: ' ';
    background: url('../../assets/img/desktop-header-decoration.svg') no-repeat;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.shell__header__logo--desktop {
  width: rsize(186);
  height: rsize(24);
}

.shell__header__logo--mobile {
  width: rsize(155);
  height: rsize(20);
}

.shell__header__nav {
  display: flex;
  align-items: center;
}

.shell__header__link {
  color: $textHigh;
  font-size: rsize(16);
  text-decoration: none;
  font-weight: bold;

  &:not(:last-child) {
    margin-right: rsize(25);
  }

  &:visited {
    color: $textHigh;
  }
}

.shell__main {
  flex: 1 0 auto;
  padding: 0 var(--space-lg);
  padding-top: 1rem;
}

.shell__main--desktop {
  margin: 0 var(--space-lg);
  padding: 0 var(--space-xxl);
}

.shell__footer {
  display: flex;
  margin-top: rsize(72);
  width: 100%;
  background: $textHigh;
  color: white;
  padding: 0 var(--space-xs);
}

.shell__footer--desktop {
  flex-direction: row;
  height: rsize(48);
  align-items: center;
  padding: 0 var(--space-sm);
}

.shell__footer__contact {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  font-size: rsize(12);
  line-height: 1.5rem;
  flex-direction: column;
}

.shell__footer__contact--desktop {
  flex-direction: row;
  margin: 0 auto;
}

.shell__footer__contact_item {
  padding: 0 var(--space-sm);

  &:first-child {
    padding-top: var(--space-sm);
  }

  &:last-child {
    padding-bottom: var(--space-sm);
  }
}

.shell__footer__contact_item--desktop {
  padding: 0.75rem var(--space-sm);

  &:first-child,
  &:last-child {
    padding: 0.75rem var(--space-sm);
  }
}

.shell__footer__logo {
  height: rsize(24);
  margin-bottom: rsize(15);
}

.shell__footer__nav {
  display: flex;
  flex-direction: column;
  background-color: $primaryDark;
  padding: rsize(15) rsize(20);
  font-size: rsize(12);
  position: relative;

  &::after {
    display: block;
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    width: rsize(215);
    height: rsize(150);
    background: url('../../assets/img/mobile-footer-yellow.svg');
    z-index: 0;
  }
}

.shell__footer__nav--desktop {
  margin-top: auto;
  position: relative;
  padding: 0 rsize(50) 0 0;
  background-color: transparent;
  flex-direction: row;
  font-size: rsize(14);

  &::after {
    bottom: rsize(-30);
    width: rsize(856);
    height: rsize(90);
    background: url('../../assets/img/desktop-footer-dark-primary.svg');
    z-index: -1;
  }
}

.shell__footer__link {
  color: $whiteColor;
  text-decoration: none;

  &--underlined {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-bottom: rsize(8);
  }
}

.shell__footer__link--desktop {
  &:not(:last-child) {
    margin-bottom: 0;
    margin-right: rsize(30);
  }
}

.mobileMenu--container {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  padding: 72px 0;

  .shell__header {
    &__nav {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__close {
      position: absolute;
      right: 12px;
      top: -54px;
    }

    &__link {
      min-height: 64px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      color: $whiteColor;
      font-size: 1.5rem;
    }
  }
}
