@import '../../../assets/scss/index';

.two-column-layout {
  max-width: rsize(1100);
  width: 100%;
  display: flex;
  padding: rsize(40) rsize(20);
  margin: 0 auto;

  &__content {
    flex: 1 3 733px;
  }

  &__sidebar {
    flex: 0 1 306px;
  }

  &--desktop,
  &--tablet {
    flex-wrap: nowrap;
    flex-direction: row;

    .two-column-layout__content {
      padding-right: rsize(30);
    }
  }

  &--mobile {
    flex-wrap: wrap;
    flex-direction: column;

    .two-column-layout__content {
      flex: 1 1 100%;
      order: 2;
    }

    .two-column-layout__sidebar {
      flex: 1 1 100%;
      order: 1;
    }
  }
}
