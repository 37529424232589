@import '../../assets/scss/index';

.external-link {
  text-decoration-line: underline;
  font-size: 1rem;
  line-height: 1.5;
  color: $primary;
  font-family: $sansSerif;

  &:hover {
    cursor: pointer;
  }
}

.external-link:visited {
  color: $primary;
}
