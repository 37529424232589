.status-banner {
  font-family: var(--font-family-body);
  font-weight: 700;
  font-size: var(--text-size-sm);
  color: var(--color-other-white);
  background-color: var(--color-neutral-main);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  text-transform: capitalize;
  background-repeat: no-repeat;
  background-position: var(--space-xs);
  padding: var(--space-xs) var(--space-sm);
}

.status-icon {
  pointer-events: none;
}

.info-banner__content {
  position: relative;
  width: 270px;
  border-radius: 0.75rem;
  border: 1px solid var(--color-other-divider);

  &::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    border-radius: 0.75rem 0.75rem 0 0;
    width: 270px;
    height: 15px;
    background-color: var(--color-info-content);
  }
}

.info-banner__wrapper {
  display: flex;
  justify-content: flex-end;
}

.info-banner__list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-top: 15px;
}

.info-banner__row {
  font-family: var(--font-family-body);
  font-weight: 500;
  color: var(--color-neutral-main);
  margin-bottom: var(--space-xs);

  &:last-child {
    margin-bottom: 0;
  }
}

.info-banner__link {
  font-weight: 700;
  color: var(--color-primary-main);
  text-decoration: none;
  cursor: pointer;

  &--icon {
    display: flex;
    align-items: center;
  }
}

.details__row {
  font-family: var(--font-family-body);
  font-size: var(--text-size-sm);

  &:first-child {
    font-weight: 700;
    color: var(--color-neutral-main);
  }

  &--leasing-agent {
    font-weight: 500;
    color: var(--color-text-emphasisLow);
  }
}

.detail__column {
  &--email {
    color: var(--color-primary-main);
    text-decoration: underline;
    cursor: pointer;
  }
}

.policy-history__column {
  padding: 0 30px 0 0;
}

.status-info {
  &:hover {
    cursor: pointer;
  }
}

.comments__field {
  height: 128px;
}

.input-refund-premium {
  background-color: var(--color-other-divider) !important;
}

.bond-number__column {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .bond-declaration__link {
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: '+';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
  }
}

.select-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;
  max-height: 2.1rem;
}

.option-label {
  display: flex;
  flex-direction: column;
  text-transform: none;
}

.credit_score_report {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 4px;
  flex-wrap: nowrap;
}

.calculated-coverage-info {
  color: #73739a;
  font-size: 12px;
  padding-top: 4px;
}

.manual-override-text {
  font-size: 12px;
  font-weight: bold;
  background-color: #ededf2;
  border-radius: 100px;
  padding: 4px 12px;
  margin-right: 5px;
}

.manual-override-icon {
  color: #73739a;
  font-size: 16px !important;
}
